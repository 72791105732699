import React, { Suspense, lazy } from "react";

import { Box } from "@mui/material";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "./components/loading";

const Notification = lazy(() => import("./pages/notification"));
const FormNotification = lazy(() => import("./pages/form/formNotification"));

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box sx={{ px: 1 }}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/" element={<></>} />
              <Route path="/notification" element={<Notification />} />
              <Route path="/notification/form" element={<FormNotification />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Box>
    </ApolloProvider>
  );
}
